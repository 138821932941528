import { reactive, ref, toRefs } from 'vue';
type FormData = {
  grabUrl: string;
  id: string | number;
  isGrab: boolean;
  shipName: string;
  url: string;
  version: number | null;
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    grabUrl: '',
    id: null,
    isGrab: true,
    shipName: '',
    url: '',
    version: null,
    ...params,
  });
  const customFormData = reactive({
    RESULT_ARRAY: [],
  });
  const rules = reactive({
    shipName: [{ required: true, message: '请输入船公司简称', trigger: 'blur' }],
    remark: [{ max: 300, message: '最大不能超过300', trigger: 'blur' }],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  return { rules, formData, formRef, setFormData, ...toRefs(customFormData) };
};
